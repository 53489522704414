import { render, staticRenderFns } from "./dialogDelete.vue?vue&type=template&id=3aaa7e88&scoped=true&"
import script from "./dialogDelete.vue?vue&type=script&lang=js&"
export * from "./dialogDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aaa7e88",
  null
  
)

export default component.exports